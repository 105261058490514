
.matrix-rain-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100; /* Higher z-index than success message overlay */
}

.matrix-rain-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: grid;
  z-index: 101; /* Ensure matrix rain is above success message overlay */
}

.matrix-character {
  color: #0f0;
  font-family: 'Press Start 2P', monospace;
  font-size: 1em;
  visibility: hidden;
  display: none;
  opacity: 0.7;
}

.matrix-character.visible {
  animation: appear-disappear 2s linear infinite;
  display: block;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes appear-disappear {
  0%, 100% {
    visibility: hidden;
    opacity: 0;
  }
  50% {
    visibility: visible;
    opacity: 1;
  }
}

.success-message-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Ensure it's above other elements */
  animation: pulseGlow 2s linear infinite;
  box-shadow: inset 0 0 20px;
}

.success-message-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: -102; /* Behind the success message */
}

.success-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #ff652f;
  font-size: 24px;
  padding: 20px 20px; /* Increase padding for more space around the message */
  border-radius: 10px; /* Increase border radius for rounded corners */
  z-index: 103; /* Higher z-index than matrix rain overlay */
  box-shadow: 0 0 20px rgba(0, 247, 21, 0.98); /* Add a subtle shadow effect */
  text-align: center; /* Center align the text */
  animation: pulseGlow 2s linear infinite;
}


@keyframes pulseGlow {
  0% {
    text-shadow: 0 0 10px #ff652f, 0 0 20px #ff652f, 0 0 30px #ff652f, 0 0 40px #ff4500, 0 0 70px #ff4500, 0 0 80px #ff4500, 0 0 100px #ff4500, 0 0 150px #ff4500;
    transform: scale(1);
  }
  50% {
    text-shadow: 0 0 20px #ff652f, 0 0 30px #ff652f, 0 0 40px #ff652f, 0 0 50px #ff4500, 0 0 80px #ff4500, 0 0 90px #ff4500, 0 0 110px #ff4500, 0 0 160px #ff4500;
    transform: scale(1.05);
  }
  100% {
    text-shadow: 0 0 10px #ff652f, 0 0 20px #ff652f, 0 0 30px #ff652f, 0 0 40px #ff4500, 0 0 70px #ff4500, 0 0 80px #ff4500, 0 0 100px #ff4500, 0 0 150px #ff4500;
    transform: scale(1);
  }
}